<template>
  <section v-if="showbox && allSingleResume">
    <div v-if="allLoadingResume" class="resumebox">loading....</div>
    <div class="resumebox">
      <ul class="headeraction">
        <li>
          <v-btn v-if="!allLoadingResume" @click="printa">Download</v-btn>
        </li>

        <li>
          <v-icon @click="closeButton"> mdi-close-circle-outline </v-icon>
        </li>
      </ul>

      <!--cv sample-->

      <div class="maincvwrapper" ref="doc">
        <div class="pdf">
          <!-- <v-container> <v-row> </v-row></v-container> -->
          <div class="header">
            <h1>
              {{ allSingleResume.resumesingle.basicInformation.fullname }}
            </h1>
            <a
              v-if="allSingleResume.resumesingle.basicInformation.email"
              :href="allSingleResume.resumesingle.basicInformation.email"
              >{{ allSingleResume.resumesingle.basicInformation.email }}</a
            >
            <div
              v-if="allSingleResume.resumesingle.basicInformation.phone != ''"
            >
              <span
                v-for="(data, index) in allSingleResume.resumesingle
                  .basicInformation.phone"
                :key="index + 'info'"
              >
                {{ data.phone
                }}<span
                  v-if="
                    index + 1 <
                      allSingleResume.resumesingle.basicInformation.phone.length
                  "
                >
                  |
                </span>
              </span>
            </div>
            <ul class="addresslist">
              <li>
                {{
                  allSingleResume.resumesingle.basicInformation.currentAddress
                }}
              </li>
            </ul>
            <span>
              (https://www.necojobs.com.np/{{ allLogin.userHandle }})
            </span>
            <h3>
              {{ allSingleResume.resumesingle.jobPreference.lookingFor }}
            </h3>
            <div class="hrline">
              <span></span>
            </div>
          </div>
          <div class="header-sub">
            <!-- <ul> -->
            <div class="center-text">
              <strong>Skills : </strong>
              <span
                v-for="(data, index) in allSingleResume.resumesingle
                  .jobPreference.skills"
                :key="index + 'skilzz'"
              >
                {{ data.skill }}
                <span
                  v-if="
                    index + 1 <
                      allSingleResume.resumesingle.jobPreference.skills.length
                  "
                  >,</span
                >
              </span>
            </div>
            <div class="center-text">
              <strong>Specilizations : </strong>
              <span
                v-for="(data, index) in allSingleResume.resumesingle
                  .jobPreference.specilizations"
                :key="index + 'spliz'"
              >
                {{ data.specilization }}
                <span
                  v-if="
                    index + 1 <
                      allSingleResume.resumesingle.jobPreference.specilizations
                        .length
                  "
                  >,</span
                >
              </span>
            </div>
            <div class="center-text btnbox">
              <strong>Categories : </strong>
              <span
                v-for="(data, index) in allSingleResume.resumesingle
                  .jobPreference.jobCategoris"
                :key="index + 'jobss'"
              >
                {{ data }}
                <span
                  v-if="
                    index + 1 <
                      allSingleResume.resumesingle.jobPreference.jobCategoris
                        .length
                  "
                  >,</span
                >
              </span>
            </div>

            <!-- </ul> -->
          </div>
          <div class="body-section">
            <h2 class="titlebox">Work Experience</h2>
            <div
              v-for="(data, index) in allSingleResume.resumesingle
                .workexperience"
              :key="index + 'we'"
            >
              <div>
                <h5>{{ data.oraganizationName }}, {{ data.jobLocation }}</h5>
              </div>
              <div>
                <ul class="titledate">
                  <li v-html="data.dutiesAndResponsibility"></li>
                  <li>
                    <span v-if="data.startedYear.year != ''"
                      >{{ data.startedYear.year }},
                      {{ data.startedYear.month }}</span
                    >
                    <span
                      v-if="
                        data.startedYear.year != '' && data.endedYear.year != ''
                      "
                      >-</span
                    >
                    <span v-if="data.endedYear.year != ''"
                      >{{ data.endedYear.year }},
                      {{ data.endedYear.month }}</span
                    >
                    <span v-if="data.isCurrentlyWorking"> present</span>
                  </li>
                </ul>
              </div>
              <div class="worklist">
                <h5>{{ data.jobTitle }}</h5>
                <ul class="listofwork">
                  <li>Job Category : {{ data.jobCategory }}</li>
                  <li>Job Level : {{ data.jobLevel }}</li>
                </ul>
              </div>
            </div>
            <h2 class="titlebox">EDUCATION</h2>
            <div
              v-for="(data, index) in allSingleResume.resumesingle.education"
              :key="index + 'edu'"
            >
              <div>
                <h5>{{ data.nameOfInstitute }}</h5>
              </div>
              <div>
                <ul class="titledate">
                  <li></li>
                  <li v-if="data.graducationYear.year != ''">
                    {{ data.graducationYear.year }}
                    {{ data.graducationYear.month }}
                    <span v-if="data.isCurrentlyStudying">present</span>
                  </li>
                  <li v-if="data.startedYear.year != ''">
                    {{ data.startedYear.year }} {{ data.startedYear.month }}
                    <span v-if="data.isCurrentlyStudying">present</span>
                  </li>
                </ul>
              </div>
              <div class="worklist">
                <h5>Education Board : {{ data.educationBoard }}</h5>
                <ul class="listofwork">
                  <li>Degree : {{ data.degree }}</li>
                  <li>Program : {{ data.educationProgram }}</li>
                  <li>Secured Marks In : {{ data.markedSecuredIn.type }}</li>
                  <li>Marks Scored : {{ data.markedSecuredIn.score }}</li>
                </ul>
              </div>
            </div>
            <h2 class="titlebox marbox">TRAINING</h2>
            <div
              v-for="(data, index) in allSingleResume.resumesingle.training"
              :key="index + 'trainn'"
            >
              <div>
                <h5>{{ data.nameOfInstitute }}</h5>
              </div>
              <div>
                <ul class="titledate">
                  <li>{{ data.nameofTraining }}</li>
                  <li>
                    {{ data.completionYear.month }}
                    {{ data.completionYear.year }}
                  </li>
                </ul>
                <div class="worklist">
                  <ul class="listofwork">
                    <li>
                      Duration : {{ data.duration.count }}
                      {{ data.duration.monthyear }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h2 class="titlebox">REFERENCE</h2>
            <div
              v-for="(data, index) in allSingleResume.resumesingle.reference"
              :key="index + 'ref'"
            >
              <div class="worklist">
                <ul class="listofwork">
                  <li>Reference Name : {{ data.referenceName }}</li>
                  <li>Organization Name : {{ data.organizationName }}</li>
                  <li>Job Title : {{ data.jobTitle }}</li>
                  <li>Email : {{ data.email }}</li>
                  <li>
                    Phone Number : {{ data.phone1 }}
                    <span v-if="data.phone2 != ''">|</span> {{ data.phone2 }}
                    <span v-if="(data.phone3 = +'')">|</span> {{ data.phone3 }}
                  </li>
                </ul>
              </div>
            </div>
            <h2>OPTIONAL PERSONAL, PATENTS, AWARDS, TECHNOLOGIES, KEYWORDS</h2>
            <div class="hrline">
              <span></span>
            </div>
            <div class="worklist">
              <ul class="listofwork">
                <li>
                  Nationality :
                  {{
                    allSingleResume.resumesingle.basicInformation.nationality
                  }}
                </li>
                <li>
                  Date of birthday :
                  {{
                    allSingleResume.resumesingle.basicInformation.dob
                      | formatDate
                  }}
                </li>
                <li>
                  Maritial Status :
                  {{
                    allSingleResume.resumesingle.basicInformation.maritialStatus
                  }}
                </li>
                <li>
                  Current Salary : ({{
                    allSingleResume.resumesingle.jobPreference.currentSalary
                      .salaryRange
                  }})
                  {{
                    allSingleResume.resumesingle.jobPreference.currentSalary
                      .currency
                  }}
                  {{
                    allSingleResume.resumesingle.jobPreference.currentSalary
                      .salary
                  }}.00{{
                    allSingleResume.resumesingle.jobPreference.expectedSalary
                      .salaryTime
                  }}
                </li>
                <li>
                  Expected Salary : ({{
                    allSingleResume.resumesingle.jobPreference.expectedSalary
                      .salaryRange
                  }})
                  {{
                    allSingleResume.resumesingle.jobPreference.expectedSalary
                      .currency
                  }}
                  {{
                    allSingleResume.resumesingle.jobPreference.expectedSalary
                      .salary
                  }}.00{{
                    allSingleResume.resumesingle.jobPreference.expectedSalary
                      .salaryTime
                  }}
                </li>
              </ul>
            </div>

            <p
              v-for="(data, index) in allSingleResume.resumesingle
                .socialaccount"
              :key="index + 'socialacc'"
            >
              Social Link : {{ data.accountLink }}
            </p>
            <div class="class30">
              <img src="@/assets/logo.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!--cv sample-->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Printd from "printd";
export default {
  name: "ResumeView",
  props: ["showbox"],
  computed: {
    ...mapGetters(["allSingleResume", "allLoadingResume", "allLogin"]),
  },
  data: () => ({
    cssText: `
.titledate{
            margin: 0 0 10px;
        }
        .pdf {
            // padding: 20px 30px;
            
        }

        .header {
            text-align: center;
        }

        .header h1 {
            text-align: center;
            margin-bottom: 10px;
            font-size: 30px;
        }

        .header .addresslist {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            justify-content: center;
            list-style: none;
            margin-left: 0;
            padding-left: 0;
        }

        .header .addresslist li {
            margin: 0 10px;
        }

        .hrline {
            position: relative;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .hrline span {
            display: block;
            height: 1px;
            background: #000;
        }

        .hrline::after {
            content: '';
            position: absolute;
            height: 20px;
            width: 20px;
            border: 1px solid #000;
            left: 0;
            transform: translateY(-50%) rotate(45deg);
            top: 50%;
            background: #fff;
        }

        .hrline::before {
            content: '';
            position: absolute;
            height: 20px;
            width: 20px;
            border: 1px solid #000;
            right: 0;
            transform: translateY(-50%) rotate(45deg);
            top: 50%;
            background: #fff;
        }

        .header-sub ul {
            display: flex;
            justify-content: center;
         
        }

        .header-sub ul li {
            margin: 0 20px;
        }

        .body-section h2 {
            text-transform: uppercase;
            padding-bottom: 10px;
                margin-top: 0;
    margin-bottom: 5px;
    font-size:16px
        }

        .body-section .titlebox {
            border-bottom: 1px solid #000;
            font-size:14px;
        }
        

        .titledate {
            display: flex;
            margin-left: 0;
            padding-left: 0;
            justify-content: space-between;
               align-items:flex-start;
        }
        .titledate li:last-of-type{
  width: 30%;
  text-align:right;
}
.titledate li:first-of-type{
  width: 70%;
}

        h5 {
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 5px;
            // margin-bottom: -15px;
        }

        .titledate {
            list-style: none;
        }

        .titledate li:last-of-type {
            list-style: none;
            font-weight: 600;
        }

        .listofwork {
            /* margin-top: 10px; */
            padding-left: 15px;
        }

        .listofwork li {
            margin-bottom: 10px;
        }

        .worklist {
            margin-bottom: 10px;
        }

        p {
            line-height: 24px;
        }
        .center-text{
  text-align: center;
    margin-bottom:5px;
}
.btnbox{
  margin-bottom:10px;
}
    `,
  }),
  methods: {
    closeButton() {
      this.$emit("closepopup", false);
    },
    printa() {
      this.d.print(this.$refs.doc, [this.cssText]);
    },
  },
  created() {
    this.Printd = window.printd;
    this.d = new Printd();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.pdf {
  padding: 20px 30px;
}

.header {
  text-align: center;
}

.header h1 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 30px;
}

.header .addresslist {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.header .addresslist li {
  margin: 0 10px;
}

.center-text {
  text-align: center;
}
.hrline {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hrline span {
  display: block;
  height: 1px;
  background: #000;
}

.hrline::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  border: 1px solid #000;
  left: 0;
  transform: translateY(-50%) rotate(45deg);
  top: 50%;
  background: #fff;
}

.hrline::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  border: 1px solid #000;
  right: 0;
  transform: translateY(-50%) rotate(45deg);
  top: 50%;
  background: #fff;
}
.header-sub {
  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
}
.header-sub ul {
  display: flex;
  justify-content: center;
}

.header-sub ul li {
  margin: 0 20px;
}

.body-section h2 {
  text-transform: uppercase;
  padding-bottom: 10px;
  @media only screen and (max-width: 991px) {
    font-size: 20px;
  }
}

.body-section .titlebox {
  border-bottom: 1px solid #000;
}
.titlebox {
  font-size: 16px;
  margin-top: -10px;
}

.titledate {
  display: flex;
  margin-left: 0;
  padding-left: 0;
  justify-content: space-between;
  align-items: flex-start;
  @media only screen and (max-width: 991px) {
    display: block;
  }
}
.titledate li:last-of-type {
  width: 30%;
  text-align: right;
}
.titledate li:first-of-type {
  width: 70%;
}
h5 {
  font-size: 18px;
  margin-bottom: 0;
}

.titledate {
  list-style: none;
  @media only screen and (max-width: 991px) {
    li:first-of-type {
      width: 0;
    }
    li:last-of-type {
      width: 100%;
      text-align: left;
    }
  }
}

.titledate li:last-of-type {
  list-style: none;
  font-weight: 600;
}

.listofwork {
  /* margin-top: 10px; */
  padding-left: 15px;
  @media only screen and (max-width: 991px) {
    padding-left: 0;
    margin-top: 20px;
  }
}

.listofwork li {
  margin-bottom: 10px;
}

.worklist {
  margin-bottom: 40px;
  @media only screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
  h5 {
    @media only screen and (max-width: 991px) {
      margin-top: 10px;
    }
  }
}

p {
  line-height: 24px;
}

li {
  margin: 0px;
}
section {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  overflow-y: scroll;

  .resumebox {
    background: $white;
    margin: 0 auto;
    padding: 40px;
    width: 900px;
    @media only screen and (max-width: 991px) {
      width: 100%;
      padding: 10px;
    }
    h1 {
      font-size: $iconfont;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}

.personaldetails {
  line-height: 30px;
}
.objectssss p {
  padding: 0 10px !important;
}
.infobox {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.headeraction {
  margin-bottom: 0;
  display: flex;
  width: 100%;
  padding-left: 0;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  li {
    padding: 0;
    margin: 0;
  }
}
.class30 img {
  width: 80px;
  margin-top: 15px;
  // float: right;
}
</style>
