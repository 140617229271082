import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  adminMenus: [],
  frontendMenus:[],
  specificPage: [],
  cousesMenus: [],
  loadingMenu: false,
  menurules:[]
});

const getters = {
  allAdminMenus: (state) => state.adminMenus,
  allFrontendMenus:(state)=>state.frontendMenus,
  allSpecificMenus: (state) => Object.assign(state.specificPage),
  allMenuRules:(state)=>state.menurules,
  allLoadingMenu: (state) => state.loadingMenu,
  allCousesMenu: (state) => state.cousesMenus,
};

const actions = {
  async fetchMenus({ commit }) {
    try {
      this.dispatch('loadingMenu');
      const response = await Axios.get(baseUrl + "access/rules", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAdminMenu", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch('loadingNotMenu');
  },
  async fetchCourseMenus({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "access/courses");
      commit("setCourseMenu", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchMenusList({ commit },data) {
    try {
      this.dispatch('loadingMenu');
      const response = await Axios.post(baseUrl + "access/rulesall", data,{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setMenuRules", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch('loadingNotMenu');
  },
  async fetchMenusFrontend({ commit }) {
    try {
      this.dispatch('loadingMenu');
      const response = await Axios.get(baseUrl + "access/menus/frontend/top");
      commit("setFrontendMenu", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch('loadingNotMenu');
  },
  async updateStatus({ commit },data) {
    try {
      this.dispatch('loadingMenu');
      const response = await Axios.put(baseUrl + "access/updateAccess",data,{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      this.dispatch("fetchMenusList")
      this.dispatch("fetchMenus")
      commit("setFrontendMenu", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch('loadingNotMenu');
  },
  async loadingMenu({ commit }) {
    commit("loadingMenuSet", true);
  },
  async loadingNotMenu({ commit }) {
    commit("loadingNotMenuSet", false);
  },
};

const mutations = {
  menuMutation: (state, menu) => (state.menus = menu),
  setMenuRules: (state, menu) => (state.menurules = menu),
  setAdminMenu: (state, menu) => (state.adminMenus = menu),
  setCourseMenu: (state, menu) => (state.cousesMenus = menu),
  setFrontendMenu: (state, menu) => (state.frontendMenus = menu),
  loadingMenuSet: (state, loading) => (state.loadingMenu = loading),
  loadingNotMenuSet: (state, loading) => (state.loadingMenu = loading),
};

export default { state, getters, actions, mutations };
