import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  attendances: [],
  attendance: [],
  attendancestatus: [],
  loadingAttedance: false,
});

const getters = {
  allAttendances: (state) => state.attendances,
  allAttendance: (state) => state.attendance,
  allloadingAttedance: (state) => state.loadingAttedance,
  allAttedanceStatus: (state) => state.attendancestatus,
};

const actions = {
  async fetchAttendance({ commit }, data) {
    try {
      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.post(baseUrl + "attendance", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAttendances", response.data);
    } catch (error) {
      commit("setAttendances", error.response.data);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async fetchAttendanceSingle({ commit }, data) {
    try {
      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.get(baseUrl + "attendance/single/" + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAttendance", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async fetchAttendanceSingleUser({ commit }) {
    try {
      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.get(baseUrl + "attendance/single",{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAttendanceStatus", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async createAttendance({ commit }, data) {
    try {
     
      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.post(baseUrl + "attendance/createEmployee", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAttendanceStatus", response.data);
    } catch (error) {
      console.log(error);
      commit("setAttendanceStatus", error.response.data);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async createAttendanceAdmin({ commit }, data) {
    try {
      const ipdata = await Axios.get('https://www.cloudflare.com/cdn-cgi/trace');
      var data1 = {
          ...data,
          attendanceFrom: ipdata.data
      }
      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.post(baseUrl + "attendance/createAdmin", data1, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push("/dashboard/attendance");
      commit("setAttendance", response.data);
    } catch (error) {
      commit("setAttendance", error.response.data);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async updateAttendanceAdmin({ commit }, data) {
    try {
      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.put(baseUrl + "attendance/updateAdmin", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAttendance", response.data.results);
    } catch (error) {
      console.log(error);
      commit("setAttendance", error.response);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async updateAttendance({ commit }, data) {
    try {
      const ipdata = await Axios.get('https://www.cloudflare.com/cdn-cgi/trace');

      var data1 = {
          ...data,
          attendanceFrom: ipdata.data
      }
      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.put(baseUrl + "attendance/updateEmployee", data1, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      
      commit("setAttendanceStatus", response.data);
    } catch (error) {
      console.log(error);
      commit("setAttendanceStatus", error.response);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async fetchSingleAttendance({ commit }, data) {
    try {

      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.get(baseUrl + "attendance/single/"+data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAttendance", response.data);
    } catch (error) {
      console.log(error);
      commit("setAttendance", error.response);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async updateAtteanceAdmin({commit},data){
    try{
      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.put(baseUrl + "attendance/updateAdmin/",data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      router.push("/dashboard/attendance");
      commit("setAttendance", response.data);
    }catch(error){
      commit("setAttendance", error.response);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async filterAttendace({commit},data){
    try{
      this.dispatch("loadingAttedanceFunc");
      const response = await Axios.post(baseUrl + "attendance/filterAttendance/",data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAttendances", response.data);
    }catch(error){
      commit("setAttendances", error.response);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async loadingAttedanceFunc({ commit }) {
    commit("loadingAttedanceSet", true);
  },
  async loadingNotAdFunc({ commit }) {
    commit("loadingNotAdSet", false);
  },
};

const mutations = {
  setAttendances: (state, menu) => (state.attendances = menu),
  setAttendance: (state, menu) => (state.attendance = menu),
  setAttendanceStatus: (state, menu) => (state.attendancestatus = menu),
  loadingAttedanceSet: (state, loading) => (state.loadingAttedance = loading),
  loadingNotAdSet: (state, loading) => (state.loadingAttedance = loading),
};

export default { state, getters, actions, mutations };
