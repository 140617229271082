import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  meta: undefined,
  metas: [],
});

const getters = {
  allMeta: (state) => state.meta,
  allMetas: (state) => state.metas,
};

const actions = {
  async addMeta({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "meta/create", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setMeta", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async getMetas({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "meta/list", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setMetas", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleMeta({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + "meta/" + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setMeta", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleMetaPage({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + "meta/page/" + data);
      commit("setMeta", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async deleteMeta({ commit }, data) {
    try {
      const response = await Axios.delete(baseUrl + "meta/delete/" + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setMetas", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setMeta: (state, data) => (state.meta = data),
  setMetas: (state, data) => (state.metas = data),
};

export default { state, getters, actions, mutations };
