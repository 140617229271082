import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  companies: [],
  basicInfoCompany: [],
  contactPerson: [],
  loadingCompany: false,
  companyLogos: [],
});

const getters = {
  allCompany: (state) => state.companies,
  allBasicInfoCompany: (state) => state.basicInfoCompany,
  allContactPerson: (state) => state.contactPerson,
  allloadingCompany: (state) => state.loadingCompany,
  allCompanyLogos: (state) => state.companyLogos,
};

const actions = {
  async createBaiscInfo({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "company/createBasicInfo",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setBaiscInfo", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async createContactPerson({ commit }, data) {
    try {
      data.forEach(async (ele) => {
        ele.resume_number = "NJ-127087C";

        let response = await Axios.post(
          baseUrl + "company/createContactPerson",
          ele,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        commit("setContactPerson", response.data);
      });
    } catch (error) {
      console.log(error);
    }
  },
  async getAllCompany({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "company/companyall", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setCompanyAll", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getBasicInformation({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "company/baiscInformation/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setBaiscInfo", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getContactPerson({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "company/contactPerson/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setContactPerson", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getCompanyLogos({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "company/companyLogo");
      commit("setCompanyLogos", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async loadingCompany({ commit }) {
    commit("loadingCompanySet", true);
  },
  async loadingNotCompany({ commit }) {
    commit("loadingNotCompanySet", false);
  },
};

const mutations = {
  setBaiscInfo: (state, company) => (state.basicInfoCompany = company),
  setContactPerson: (state, company) => (state.contactPerson = company),
  setCompanyAll: (state, company) => (state.companies = company),
  loadingCompanySet: (state, loading) => (state.loadingCompany = loading),
  loadingNotCompanySet: (state, loading) => (state.loadingCompany = loading),
  setCompanyLogos: (state, company) => (state.companyLogos = company),
};

export default { state, getters, actions, mutations };
