import Dashboard from "../views/dashboard/Dashboard";
const backendmenu = [
  {
    path: "/dashboard",
    component: Dashboard,
    children: [
      //table page
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/dashboard/views/Home"),
      },
      {
        path: "/dashboard/ads",
        name: "AdsList",
        component: () => import("../views/dashboard/views/Ads"),
      },
      {
        path: "/dashboard/trainings",
        name: "Training",
        component: () => import("../views/dashboard/views/Training"),
      },
      {
        path: "/dashboard/trainingCategory",
        name: "TrainingCategory",
        component: () => import("../views/dashboard/views/TrainingCategory"),
      },
      {
        path: "/dashboard/trainingRegistrations",
        name: "TrainingRegistration",
        component: () =>
          import("../views/dashboard/views/TrainingRegistration"),
      },
      {
        path: "/dashboard/attendance",
        name: "Attendance",
        component: () => import("../views/dashboard/views/Attendance"),
      },
      {
        path: "/dashboard/companylist",
        name: "Company",
        component: () => import("../views/dashboard/views/Company"),
      },
      {
        path: "/dashboard/userlist",
        name: "User",
        component: () => import("../views/dashboard/views/User"),
      },
      {
        path: "/dashboard/resumelist",
        name: "Resume",
        component: () => import("../views/dashboard/views/Resume"),
      },
      {
        path: "/dashboard/resumecv",
        name: "CV",
        component: () => import("../views/dashboard/views/Cv"),
      },
      {
        path: "/dashboard/categories",
        name: "Category",
        component: () => import("../views/dashboard/views/Category"),
      },
      {
        path: "/dashboard/subcategories",
        name: "SubCategory",
        component: () => import("../views/dashboard/views/Subcategory"),
      },
      {
        path: "/dashboard/jobs",
        name: "Job",
        component: () => import("../views/dashboard/views/Job"),
      },
      {
        path: "/dashboard/pages",
        name: "Page",
        component: () => import("../views/dashboard/views/Page"),
      },
      {
        path: "/dashboard/blogs",
        name: "blogs",
        component: () => import("../views/dashboard/views/Blog"),
      },
      {
        path: "/dashboard/menuassign",
        name: "blogs",
        component: () => import("../views/dashboard/views/MenuAssigned"),
      },

      //single page
      {
        path: "/dashboard/company/:slug",
        name: "SingleCompanyDashboard",
        component: () =>
          import("../views/dashboard/views/single/CompanyDetail"),
      },
      {
        path: "/dashboard/user/add",
        name: "AddUser",
        component: () => import("../views/dashboard/views/single/AddUser"),
      },
      {
        path: "/dashboard/category/add",
        name: "AddCategory",
        component: () => import("../views/dashboard/views/single/AddCategory"),
      },
      {
        path: "/dashboard/subcategory/add",
        name: "AddSubcategory",
        component: () =>
          import("../views/dashboard/views/single/AddSubCategory"),
      },
      {
        path: "/dashboard/training/add",
        name: "AddTraining",
        component: () => import("../views/dashboard/views/single/AddTraining"),
      },
      {
        path: "/dashboard/trainingCategory/add",
        name: "AddTrainingCategory",
        component: () =>
          import("../views/dashboard/views/single/AddTrainingCategory"),
      },
      {
        path: "/dashboard/trainingCategory/add/:id",
        name: "AddTrainingCategory",
        component: () =>
          import("../views/dashboard/views/single/AddTrainingCategory"),
      },
      {
        path: "/dashboard/training/view/:slug",
        name: "ViewUsers",
        component: () => import("../views/dashboard/views/single/AppliedUser"),
      },
      {
        path: "/dashboard/menuassign/add",
        name: "AddMenuAccess1",
        component: () =>
          import("../views/dashboard/views/single/AddMenuAccess"),
      },
      {
        path: "/dashboard/resume/add",
        name: "AddMenuAccess2",
        component: () => import("../views/dashboard/views/single/AddResume"),
      },
      {
        path: "/dashboard/job/add/:company",
        name: "AddJob",
        component: () => import("../views/dashboard/views/single/AddJob"),
      },
      {
        path: "/dashboard/company/add",
        name: "AddMenuAccess4",
        component: () => import("../views/dashboard/views/single/AddCompany"),
      },
      {
        path: "/dashboard/attendance/add",
        name: "AddMenuAccess5",
        component: () =>
          import("../views/dashboard/views/single/AddAttendance"),
      },
      {
        path: "/dashboard/cv/add",
        name: "AddMenuAccess6",
        component: () => import("../views/dashboard/views/single/AddCv"),
      },
      {
        path: "/dashboard/page/add",
        name: "AddPage",
        component: () => import("../views/dashboard/views/single/AddPage"),
      },
      {
        path: "/dashboard/post/add/:slug",
        name: "AddBlog",
        component: () => import("../views/dashboard/views/single/AddPost"),
      },
      {
        path: "/dashboard/post/add/:slug/:title",
        name: "AddBlogTitle",
        component: () => import("../views/dashboard/views/single/AddPost"),
      },
      {
        path: "/dashboard/menuassign/add",
        name: "AddMenuAssign",
        component: () =>
          import("../views/dashboard/views/single/AddMenuAccess"),
      },
      {
        path: "/dashboard/menuassign/add/:id",
        name: "AddMenuAssign",
        component: () =>
          import("../views/dashboard/views/single/AddMenuAccess"),
      },

      //update page

      {
        path: "/dashboard/category/add/:slug",
        name: "AddCategoryUpd",
        component: () => import("../views/dashboard/views/single/AddCategory"),
      },
      {
        path: "/dashboard/subcategory/add/:slug",
        name: "AddSubcategoryUpd",
        component: () =>
          import("../views/dashboard/views/single/AddSubCategory"),
      },
      {
        path: "/dashboard/training/add/:slug",
        name: "AddTrainingUpd",
        component: () => import("../views/dashboard/views/single/AddTraining"),
      },
      {
        path: "/dashboard/attendance/add/:id",
        name: "AddAttendanceId",
        component: () =>
          import("../views/dashboard/views/single/AddAttendance"),
      },
      {
        path: "/dashboard/resume/add/:slug",
        name: "ResumeUpdateDash",
        component: () => import("../views/dashboard/views/single/AddResume"),
      },
      {
        path: "/dashboard/job/add/:company/:slug",
        name: "JobUpdateDash",
        component: () => import("../views/dashboard/views/single/AddJob"),
      },
      {
        path: "/dashboard/company/add/:slug",
        name: "CompanyUpdateDash",
        component: () => import("../views/dashboard/views/single/AddCompany"),
      },
      {
        path: "/dashboard/attendance/add/:slug",
        name: "AttendanceUpdate",
        component: () =>
          import("../views/dashboard/views/single/AddAttendance"),
      },
      {
        path: "/dashboard/cv/add/:slug",
        name: "AddMenuAccessUpd",
        component: () => import("../views/dashboard/views/single/AddCv"),
      },
      {
        path: "/dashboard/page/add/:slug",
        name: "AddPageUpd",
        component: () => import("../views/dashboard/views/single/AddPage"),
      },
      {
        path: "/dashboard/blog/add/:slug",
        name: "AddBlogUpd",
        component: () => import("../views/dashboard/views/single/AddBlog"),
      },
      {
        path: "/dashboard/assets",
        name: "Banner",
        component: () => import("../views/dashboard/views/Asset"),
      },
      {
        path: "/dashboard/assets/add",
        name: "Banner",
        component: () => import("../views/dashboard/views/single/AddAsset"),
      },
      {
        path: "/dashboard/videos",
        name: "Video",
        component: () => import("../views/dashboard/views/Video"),
      },
      {
        path: "/dashboard/video/add",
        name: "AddVideo",
        component: () => import("../views/dashboard/views/single/AddVideo"),
      },
      {
        path: "/dashboard/video/add/:id",
        name: "UpdateVideo",
        component: () => import("../views/dashboard/views/single/AddVideo"),
      },
      {
        path: "/dashboard/vidoesCategory",
        name: "VideoCategory",
        component: () => import("../views/dashboard/views/VideoCategory"),
      },
      {
        path: "/dashboard/videoCategory/add",
        name: "AddVideoCategory",
        component: () =>
          import("../views/dashboard/views/single/AddVideoCategory"),
      },
      {
        path: "/dashboard/videoCategory/add/:slug",
        name: "AddVideoCategoryUpd",
        component: () =>
          import("../views/dashboard/views/single/AddVideoCategory"),
      },
      {
        path: "/dashboard/vidoesSubcategory",
        name: "VideoSubcategory",
        component: () => import("../views/dashboard/views/VideoSubcategory"),
      },
      {
        path: "/dashboard/videoSubcategory/add",
        name: "AddVideoSubcategory",
        component: () =>
          import("../views/dashboard/views/single/AddVideoSubcategory"),
      },
      {
        path: "/dashboard/videoSubcategory/add/:slug",
        name: "AddVideoSubcategoryUpd",
        component: () =>
          import("../views/dashboard/views/single/AddVideoSubcategory"),
      },
      {
        path: "/dashboard/video/edit/:id",
        name: "EditVideo",
        component: () => import("../views/dashboard/views/single/AddVideo"),
      },
      {
        path: "/dashboard/meta",
        name: "Seo",
        component: () => import("../views/dashboard/views/Meta"),
      },
      {
        path: "/dashboard/meta/add",
        name: "MetaAdd",
        component: () => import("../views/dashboard/views/single/AddMeta"),
      },
      {
        path: "/dashboard/meta/add/:id",
        name: "MetaAdd",
        component: () => import("../views/dashboard/views/single/AddMeta"),
      },
      {
        path: "/dashboard/reviews",
        name: "Reviews",
        component: () => import("../views/dashboard/views/Reviews"),
      },
    ],
  },
];

export default backendmenu;
