import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  searchdata: [],
  propertypagition: [],
  propertydetail: [],
  singleProperty: [],
  paginationProperty: [],
  searchCode: [],
  categorytosearch: [],
  dashboardCount: [],
  mldata: [],
  fasttabledata: [],
});

const getters = {
  allSearchData: (state) => state.searchdata,
  allPropertyDetailData: (state) => state.propertydetail,
  allSingleProperty: (state) => state.singleProperty,
  allPaginationProperty: (state) => state.paginationProperty,
  allSearchCode: (state) => state.searchCode,
  allCategoryToSearch: (state) => state.categorytosearch,
  allDashboardCount: (state) => state.dashboardCount,
  allMLdata: (state) => state.mldata,
  allFastTableData: (state) => state.fasttabledata,
};

const actions = {
  async fetchSearchData({ commit }, data) {
    try {
      const searchdata = await Axios.post(baseUrl + "searchdata", data);
      commit("setSearchData", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchPropertyDetails({ commit }, data) {
    try {
      const searchdata = await Axios.post(baseUrl + "propertydetail", data);
      console.log(searchdata.data);
      commit("setPropertyDetail", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchPaginationPropertyDetails({ commit }, data) {
    try {
      const searchdata = await Axios.post(baseUrl + "propertypagination", data);
      console.log(searchdata.data);
      commit("setPropertyPagination", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSearchCodeData({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "searchdata/searchcode",
        data
      );
      console.log(searchdata.data);
      commit("setSearchCode", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSinglePropertyDetails({ commit }, id) {
    try {
      const searchdata = await Axios.get(
        baseUrl + "propertydetail/singledata/" + id
      );
      console.log(searchdata.data);
      commit("setSinglePropertyDetail", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchcategorytosearch({ commit }) {
    try {
      const paginatedproperty = await Axios.post(baseUrl + "pageurl");
      commit("setCategoryToSearch", paginatedproperty.data);
    } catch (error) {
      console.log(error);
    }
  },
  async dashboardCount({ commit }) {
    try {
      const paginatedproperty = await Axios.get(baseUrl + "dashboard");
      commit("setDashboardCount", paginatedproperty.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchMlData({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "propertydetail/mldata",
        data
      );
      console.log(searchdata.data);
      commit("setMlData", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFasttableData({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "propertydetail/fasttable",
        data
      );
      console.log(searchdata.data);
      commit("setFasttableData", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setSearchData: (state, search) => (state.searchdata = search),
  setPropertyDetail: (state, search) => (state.propertydetail = search),
  setSinglePropertyDetail: (state, search) => (state.singleProperty = search),
  setPropertyPagination: (state, search) => (state.paginationProperty = search),
  setSearchCode: (state, search) => (state.searchCode = search),
  setCategoryToSearch: (state, search) => (state.categorytosearch = search),
  setDashboardCount: (state, countdata) => (state.dashboardCount = countdata),
  setMlData: (state, countdata) => (state.mldata = countdata),
  setFasttableData: (state, countdata) => (state.fasttabledata = countdata),
};

export default { state, getters, actions, mutations };
