<template>
  <section class="headerdashboard">
    <v-card class="mx-auto">
      <v-navigation-drawer class="navigationback" permanent>
        <template v-if="allLoadingMenu">
          <v-list>
            <div class="menulist" v-for="n in 10" :key="n">
              <v-skeleton-loader
                max-width="300"
                type="image"
                height="25"
              ></v-skeleton-loader>
            </div>
          </v-list>
        </template>
        <template v-if="!allLoadingMenu">
          <v-list>
            <v-list-item v-for="item in allAdminMenus" :key="item.title" link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link :to="item.route">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link to="/dashboard/meta">
                  <v-list-item-title>Seo</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item class="custom-menu">
              <v-list-item-icon>
                <v-icon v-if="!dropdown" @click="dropdown = true">
                  mdi-chevron-right
                </v-icon>
                <v-icon v-if="dropdown" @click="dropdown = false">
                  mdi-chevron-down
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>Videos</span>
                <ul class="menus" v-if="dropdown">
                  <li for="">
                    <router-link to="/dashboard/videos">Video List</router-link>
                  </li>
                  <li for="">
                    <router-link to="/dashboard/vidoesCategory"
                      >Category</router-link
                    >
                  </li>
                  <li for="">
                    <router-link to="/dashboard/vidoesSubcategory"
                      >Courses</router-link
                    >
                  </li>
                </ul>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="custom-menu2">
              <v-list-item-icon>
                <v-icon> mdi-chevron-right </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <router-link to="/dashboard/reviews">Reviews</router-link>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
      <div>
        <v-btn block @click="logoutbutton" class="logoutbtn">
          <v-icon class="logout">mdi-power</v-icon> Logout
        </v-btn>
      </div>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  props: ["mini"],
  computed: mapGetters(["allAdminMenus", "allLoadingMenu"]),
  data() {
    return {
      dropdown: false,
    };
  },
  methods: {
    logoutbutton() {
      this.logout();
    },
    ...mapActions(["fetchMenus", "logout"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.left-fix {
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  .menuitems {
    color: $menulistcolor;
    font-size: $normalfont;
    display: block;
    text-decoration: none;
    padding: 5px 15px;
    margin-bottom: 5px;
    position: relative;
    // margin-left: 20px;
  }
  i {
    color: $menulistcolor;
    position: absolute;
    top: 18%;
    font-size: $iconfont;
  }
  .icon {
    &-left {
      left: -15px;
    }
    &-right {
      right: 0;
    }
  }
}
a {
  color: $dark !important;
  text-decoration: none;
  .v-list-item__title {
    font-size: $normalfont !important;
  }
}
.menulist {
  margin: 10px;
}
.navigationback {
  background: $menucolor !important;
}
.top-fix {
  position: fixed;
  left: 0;
  top: 0;
  height: 6%;
  background: $white;
  width: 85%;
  margin-left: 15%;
  box-sizing: border-box;
  padding: 20px;
  z-index: 1;
}
ul {
  list-style: none;
  padding-left: 0;
}
.logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 150px;
  }
}
.tag {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: $tagcolor;
  display: block;
  font-size: $smallfont;
  padding: 3px 8px;
  color: $white;
  border-radius: 5px;
}
.right-actions {
  display: flex;
  justify-content: flex-end;
}
.headerdashboard {
  .v-sheet {
    box-shadow: none !important;
  }
}
.logoutbtn {
  position: fixed;
  bottom: 0px;
  left: 0;
  background: $tagcolor !important;
  color: $white !important;
  .v-icon {
    margin-right: 10px;
  }
  border-radius: 0 !important;
}
.custom-menu {
  margin-top: -15px;

  span {
    font-size: 15px;
    font-weight: 500;
  }
}
.menus {
  margin-top: 5px;
  li {
    padding: 8px 0;
    font-size: 14px;
    font-weight: 500;
  }
}
.custom-menu2 {
  padding-bottom: 30px;
}
</style>
