import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import ImageUploader from "vue-image-upload-resize";
import VueQuillEditor from "vue-quill-editor";
import VueSocialSharing from "vue-social-sharing";
import moment from "moment";
import DatetimePicker from "vuetify-datetime-picker";
import VueMeta from "vue-meta";

Vue.use(require("vue-moment"));

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(DatetimePicker);

Vue.use(VueSocialSharing);

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

Vue.use(VueQuillEditor);

Vue.use(ImageUploader);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MMM-YYYY");
  }
});
Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MMM-YYYY HH:MM:SS:A");
  }
});
import VueCoreVideoPlayer from "vue-core-video-player";

Vue.use(VueCoreVideoPlayer);

Vue.config.productionTip = false;
export const baseUrl = "https://app.necojobs.com.np/api/v1/";
// export const baseUrl = "http://localhost:3000/api/v1/";

// export const baseUrl = "http://192.168.1.76:3000/api/v1/";
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
