import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  homejobs: [],
  loadingHome: false,
  companyFront: [],
  homeCategoriesJob: [],
  assets: [],
  categoriesHome: [],
});

const getters = {
  allHomeJob: (state) => state.homejobs,
  allloadingHome: (state) => state.loadingHome,
  allCompanyFront: (state) => state.companyFront,
  allHomeCategoriesJob: (state) => state.homeCategoriesJob,
  allAssets: (state) => state.assets,
  allCategoryHome: (state) => state.categoriesHome,
};

const actions = {
  async getHomeJob({ commit }) {
    try {
      this.dispatch("loadingHomeFunc");
      const response = await Axios.get(baseUrl + "job/homepage");
      commit("setHomeJob", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingHoneNotFunc");
  },
  async getCompany({ commit }, data) {
    try {
      let headerData = {};
      if (localStorage.getItem("token")) {
        headerData = {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        };
      }
      this.dispatch("loadingHomeFunc");
      const response = await Axios.get(
        baseUrl + "company/singlecompany/" + data,
        headerData
      );
      commit("setCompany", response.data);
    } catch (error) {
      
      console.log(error);
    }
    this.dispatch("loadingHoneNotFunc");
  },

  async getCategoriesHome({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "category/homeCategory");
      commit("setHomeCategoriesJob", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingHoneNotFunc");
  },
  async fetchCategory({ commit }, data) {
    try {
      this.dispatch("loadingHoneNotFunc");
      const response = await Axios.post(baseUrl + "category/category", data);
      commit("setCategory", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingHoneNotFunc");
  },

  async loadingHomeFunc({ commit }) {
    commit("loadingHomeSet", true);
  },
  async loadingHoneNotFunc({ commit }) {
    commit("loadingNotHomeSet", false);
  },

  async getAssets({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "fileupload/assets", data);
      commit("setAssets", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async postAsset({ commit }, data) {
    try {
      let headerData = {};
      if (localStorage.getItem("token")) {
        headerData = {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        };
      }
      const response = await Axios.post(
        baseUrl + "fileupload/addAsset",
        data,
        headerData
      );
      commit("setAssets", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async deleteAsset({ commit }, data) {
    try {
      let headerData = {};
      if (localStorage.getItem("token")) {
        headerData = {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        };
      }
      const response = await Axios.delete(
        baseUrl + "fileupload/assets/" + data,
        headerData
      );
      commit("setAssets", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setCategory: (state, data) => (state.categories = data),
  setHomeJob: (state, data) => (state.homejobs = data),
  setCompany: (state, data) => (state.companyFront = data),
  setHomeCategoriesJob: (state, data) => (state.homeCategoriesJob = data),
  loadingHomeSet: (state, loading) => (state.loadingHome = loading),
  loadingNotHomeSet: (state, loading) => (state.loadingHome = loading),
  setAssets: (state, data) => (state.assets = data),
};

export default { state, getters, actions, mutations };
