import Vue from "vue";
import Vuex from "vuex";
import menus from "./modules/menus";
import product from "./modules/product";
import user from "./modules/user";
import dashboard from "./modules/dashboard";
import category from "./modules/category";
import resume from "./modules/resume";
import company from "./modules/company";
import job from "./modules/job";
import home from "./modules/home";
import trainings from "./modules/trainings";
import attendance from "./modules/attendance";
import post from "./modules/post";
import video from "./modules/video";
import meta from "./modules/meta";
import review from "./modules/review";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    menus,
    product,
    user,
    dashboard,
    category,
    resume,
    company,
    job,
    home,
    trainings,
    attendance,
    post,
    video,
    meta,
    review,
  },
});
