import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  review: {},
  reviews: [],
  loadingReview: false,
});

const getters = {
  allReviews: (state) => state.reviews,
  singleReview: (state) => state.review,
  isLoadingReview: (state) => state.loadingReview,
};
const actions = {
  async createReview({ commit }, data) {
    try {
      commit("setLoadingReview", true);
      const response = await Axios.post(baseUrl + "post/review", data);
      commit("setReview", response.data);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      commit("setLoadingReview", false);
    }
  },

  async fetchReviewList({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "post/reviewlist", data);
      commit("setReviewList", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async deleteReviewAction({ commit }, id) {
    if (!id) {
      console.error("No review ID provided for deletion");
      return false;
    }

    try {
      commit("loadingReview", true);
      const response = await Axios.delete(`${baseUrl}post/review/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });

      if (response.data.success) {
        commit("removeReview", id);
        return true;
      } else {
        throw new Error("Failed to delete Revoew");
      }
    } catch (error) {
      console.error("Error deleting Review:", error);
      return false;
    } finally {
      commit("loadingReview", false);
    }
  },
};

const mutations = {
  setReview: (state, review) => (state.review = review),
  setReviewList: (state, reviews) => (state.reviews = reviews),
  setLoadingReview: (state, loading) => (state.loadingReview = loading),
  removeReview: (state, id) => {
    state.reviews.results = state.reviews.results.filter(
      (review) => review._id !== id
    );
  },
};

export default { state, getters, actions, mutations };
