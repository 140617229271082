<template>
  <section>
    <div class="mobile-nav" v-if="!menustatus">
      <ul class="topaction nolist">
        <li>
          <router-link to="/">
            <img src="@/assets/logo.png" alt="" />
          </router-link>
        </li>
        <li>
          <v-app-bar-nav-icon @click="menustatusOn"></v-app-bar-nav-icon>
        </li>
      </ul>
    </div>

    <div class="belowlogomb" v-if="menustatus">
      <div class="mobile-nav" v-if="menustatus">
        <ul class="topaction nolist">
          <li>
            <router-link to="/">
              <img src="@/assets/logo.png" alt="" />
            </router-link>
          </li>
          <li>
            <v-icon @click="menustatusOff"> mdi-close-circle-outline </v-icon>
          </li>
        </ul>
      </div>
      <ul class="authorizationlink">
        <li
          v-if="allLogin.token != undefined && allLogin.user.role != 'company'"
        >
          Welcome, {{ allLogin.user.fullname }} | Logout
          <v-icon class="logout" @click="logoutFunction"> mdi-power </v-icon>
        </li>
        <li
          v-if="allLogin.token != undefined && allLogin.user.role == 'company'"
        >
          Logout<v-icon class="logout" @click="logoutFunction">
            mdi-power
          </v-icon>
          <span v-if="allSingleCompany.results">
            Welcome,
            <span
              v-if="
                allSingleCompany.results.slug == null &&
                allSingleCompany.results.slug == undefined
              "
              >{{ allLogin.user.fullname }} </span
            ><router-link
              v-if="
                allSingleCompany.results.slug != null &&
                allSingleCompany.results.slug != undefined
              "
              :to="`/singeCompany/${allSingleCompany.results.slug}`"
              >{{ allLogin.user.fullname }}
            </router-link></span
          >
        </li>
        <template v-if="allLogin.token != undefined">
          <li v-if="allLogin.user.role == 'admin'">
            <router-link to="/dashboard" class="dashboardlink"
              >Dashboard</router-link
            >
          </li>
          <li v-if="allLogin.user.role == 'employee'">
            <router-link to="/dashboard" class="dashboardlink"
              >Dashboard</router-link
            >
          </li>
        </template>

        <li v-if="allLogin.token == undefined">
          Job Seeker:<router-link to="/login">Login</router-link>
          <router-link to="/registration">Register</router-link>
        </li>
        <li v-if="allLogin.token == undefined">
          Employer:<router-link to="/login">Login</router-link>
          <router-link to="/registercompany">Register</router-link>
        </li>
      </ul>
      <!-- 
      <div class="dividerr" v-if="menustatus"></div> -->
      <div class="navigation" v-if="menustatus">
        <ul class="navigationlist">
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <li class="listmenu" @click="toggleBrowseJobs">
            Browse Jobs
            <v-icon v-if="dropoffBrowseJobs"> mdi-chevron-down </v-icon>
            <v-icon v-if="!dropoffBrowseJobs"> mdi-chevron-right </v-icon>
            <ul class="dropdown-list" v-if="dropoffBrowseJobs">
              <li
                v-for="(data, index) in allHomeCategoriesJob.results"
                :key="index"
                class="browse-dropdown"
              >
                <img
                  v-if="data.image"
                  :src="'https://app.necojobs.com.np' + data.image"
                  alt=""
                  style="height: 20px; object-fit: contain; width: 10%"
                />

                <p class="jobs-count">
                  <router-link :to="`/category/${data.slug}`">
                    {{
                      data.title.length > 24
                        ? data.title.slice(0, 36) + "..."
                        : data.title
                    }}
                  </router-link>
                  <span>({{ data.jobs }})</span>
                </p>
              </li>
            </ul>
          </li>

          <li v-for="(data, index) in allFrontendMenus" :key="index">
            <router-link :to="data.route"> {{ data.title }}</router-link>
          </li>
          <li>
            <router-link to="/postajob"> Post A Job</router-link>
          </li>

          <li class="listmenu" @click="toggledropCourse">
            Courses <v-icon v-if="dropoffCourse"> mdi-chevron-down </v-icon>
            <v-icon v-if="!dropoffCourse"> mdi-chevron-right </v-icon>
            <ul class="dropdown-list" v-if="dropoffCourse">
              <li v-for="(ele, index) in allCousesMenu" :key="index">
                <ul>
                  <li>
                    <strong>
                      {{ ele.category.title }}
                    </strong>
                  </li>
                  <li v-for="(course, index) in ele.courses" :key="index">
                    <router-link :to="`/course/${course.slug}`">{{
                      course.trainingTitle
                    }}</router-link>
                  </li>
                </ul>
                <!-- <router-link :to="ele.slug">{{ ele.title }}</router-link> -->
              </li>
            </ul>
          </li>

          <li class="listmenu" @click="toggledrop">
            Blogs <v-icon v-if="dropoff"> mdi-chevron-down </v-icon>
            <v-icon v-if="!dropoff"> mdi-chevron-right </v-icon>
            <ul class="dropdown-list" v-if="dropoff">
              <li v-for="(ele, index) in blogmenus" :key="index">
                <router-link :to="ele.slug">{{ ele.title }}</router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="
              allLogin.token != undefined &&
              allLogin.user.role == 'user' &&
              allResumeStatus >= 7
            "
          >
            <span @click="viewResume">View Resume</span>
          </li>
        </ul>
      </div>
      <div class="navigation" v-if="menustatus">
        <ul class="navigationlist">
          <li>
            <router-link to="/privacypolicy">Privacy Policy</router-link>
          </li>
          <li>
            <router-link to="/termsncondition">Terms & Conditions</router-link>
          </li>
          <li>
            <router-link to="/">Contact</router-link>
          </li>
        </ul>
      </div>

      <!-- <ul class="sociallink" v-if="menustatus">
        <li>
              <a href="https://www.facebook.com/Necojobs" target="_blank"
                ><v-icon> mdi-facebook </v-icon></a
              >
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/neco-jobs-pvt-ltd/"
                target="_blank"
                ><v-icon> mdi-linkedin </v-icon></a
              >
            </li>
            <li>
              <a href="https://x.com/Necojobs" target="_blank"
                ><v-icon> mdi-twitter </v-icon></a
              >
            </li>
            <li>
              <a href="https://www.instagram.com/necojobs/" target="_blank"
                ><v-icon> mdi-instagram </v-icon></a
              >
            </li>
            <li>
              <a
                href="https://www.youtube.com/@neco2019/shorts"
                target="_blank"
                ><v-icon> mdi-youtube </v-icon></a
              >
            </li>
      </ul> -->
    </div>
    <ResumeView :showbox="showbox" @closepopup="closepopup" />
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ResumeView from "@/components/ResumeView";
export default {
  name: "Headermb",
  components: { ResumeView },
  computed: {
    ...mapGetters([
      "allLogin",
      "allFrontendMenus",
      "allSingleCompany",
      "allResumeStatus",
      "allCousesMenu",
      "allHomeCategoriesJob",
    ]),
  },
  data: () => ({
    showbox: true,
    menustatus: false,
    dropoff: false,
    dropoffCourse: false,
    dropoffBrowseJobs: false,
    menulist: [
      {
        title: "Home",
        slug: "/about",
        icon: "mdi-home",
      },
      {
        title: "About",
        slug: "/about",
      },
      {
        title: "Blog",
        slug: "/about",
      },
      {
        title: "Training",
        slug: "/about",
      },
      {
        title: "Find Jobs",
        slug: "/about",
      },
    ],
    blogmenus: [
      {
        title: "Career Tips",
        slug: "/blogs/career-tips",
      },
      {
        title: "Business Tips",
        slug: "/blogs/business-tips",
      },
      {
        title: "Information",
        slug: "/blogs/information",
      },
      {
        title: "Education",
        slug: "/blogs/education",
      },
    ],
    drawer: false,
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    ...mapActions([
      "fetchMenusFrontend",
      "logout",
      "getSingleCompanyUser",
      "singelResume",
      "getResumeStatus",
      "fetchCourseMenus",
      "getCategoriesHome",
    ]),
    logoutFunction() {
      this.logout();
    },
    closeCV() {
      // console.log("hey");
    },
    menustatusOn() {
      this.menustatus = true;
    },
    menustatusOff() {
      this.menustatus = false;
    },
    viewResume() {
      this.singelResume("NJ-100000");
      this.showbox = true;
    },
    closepopup(e) {
      this.showbox = e;
    },
    toggledrop() {
      this.dropoff = !this.dropoff;
    },
    toggledropCourse() {
      this.dropoffCourse = !this.dropoffCourse;
    },
    toggleBrowseJobs() {
      this.dropoffBrowseJobs = !this.dropoffBrowseJobs;
    },
  },
  async created() {
    this.showbox = false;
    this.fetchMenusFrontend();
    if (
      this.allLogin.user != undefined &&
      this.allLogin.user.role == "company"
    ) {
      this.getSingleCompanyUser({ id: this.allLogin.user.userHandle });
    }
    await this.fetchCourseMenus();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dropdown-list {
  margin-left: 0;
  padding-left: 0;
  li {
    margin-left: 0;
    padding-left: 0 !important;
  }
}
.mobile-nav {
  .topaction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    li {
      margin: 0;
      .v-icon {
        cursor: pointer;
      }
      img {
        width: 80px;
      }
    }
  }
}
.mobile-nav2 {
  .topaction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    li {
      margin: 0;
      .v-icon {
        cursor: pointer;
      }
    }
  }
}
// .dividerr {
//   border: 1px solid #f0f0f0;
//   margin: 10px 0;
//   width: 100%;
// }

.navigation {
  .navigationlist {
    font-size: $normalfont;
    display: block;
    border-bottom: 2px solid #f0f0f0;
    padding: 15px 0;
    li {
      display: block;
      padding: 10px 10px;
    }
    a {
      text-decoration: none;
      color: $dark;
      &:hover {
        color: $tagcolor;
        text-decoration: underline;
      }
    }
  }
}

.sociallink {
  padding: 10px 0 20px 17px;
  li {
    margin: 0 0 0 3px;
  }
  a {
    padding: 0 5px;
  }
  .v-icon {
    font-size: 30px !important;
  }
}

.authorizationlink {
  margin-right: 15px;
  padding: 15px 0;
  margin-left: 0;
  border-bottom: 2px solid #f0f0f0;
  & > li {
    position: relative;
    padding: 10px;
    font-size: $normalfont;
    color: $dark;
    cursor: pointer;
    display: block;
    a {
      background: $tagcolor;
      color: $white;
      padding: 5px 12px;
      border-radius: 5px;
      margin: 0 0 0 7px;
      text-decoration: none;
      &:hover {
        color: $tagcolor;
        border: 1px solid $tagcolor;
        background: transparent;
      }
    }
  }
  .dropdown {
    display: none;
    flex-flow: column;
    padding-left: 0;
    margin-left: 0;
    box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
    position: absolute;
    background: $white;
    top: 100%;
    z-index: 999;
    li {
      margin: 0;
      a {
        color: $dark;
        text-decoration: none;
        padding: 5px 15px;
        display: block;
        width: 150px;
      }
    }
  }
}
.belowlogomb {
  position: fixed;
  z-index: 999;
  background: $white;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  .mobile-nav {
    border-bottom: 2px solid #f0f0f0;
  }
}
@media screen and (max-width: 992px) {
  .mobile-nav {
    .topaction {
      box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%),
        0 1px 3px 1px rgb(66 66 66 / 16%);
    }
  }
}
.browse-dropdown {
  display: flex !important;
  align-items: center;
  margin-left: 22px !important;
}
</style>
