import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  dashboard: [],
  loadingDaahboard: false,
});

const getters = {
  allDashboard: (state) => state.dashboard,
  allLoadingDash:(state)=>state.loadingDaahboard
};

const actions = {
  async fetchDashboard({ commit }) {
    try {
      this.dispatch('loadingDash');
      const response = await Axios.get(baseUrl + "training/dashboard", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setDashboard", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch('loadingNotDash');
  },
  async loadingDash({ commit }) {
    commit("loadingDashSet", true);
  },
  async loadingNotDash({ commit }) {
    commit("loadingNotDashSet", false);
  },
};

const mutations = {
  setDashboard: (state, menu) => (state.dashboard = menu),
  loadingDashSet: (state, loading) => (state.loadingDaahboard = loading),
  loadingNotDashSet: (state, loading) => (state.loadingDaahboard = loading),
};

export default { state, getters, actions, mutations };
