import Authorization from '../views/authorization/Authorization'
const backendmenu = [
    {
        path: '/authorization',  component: Authorization,
        children: [
          {
            path: '/login',
            name: 'Login',
            component: () => import(/* webpackChunkName: "Login" */ '../views/authorization/views/Login')
          },
          {
            path: '/verify',
            name: 'Verify',
            component: () => import(/* webpackChunkName: "Verify" */ '../views/authorization/views/Verify')
          },
          {
            path: '/forget',
            name: 'Verify',
            component: () => import(/* webpackChunkName: "Verify" */ '../views/authorization/views/Forget')
          },
          {
            path: '/setpassword',
            name: 'Verify',
            component: () => import(/* webpackChunkName: "Verify" */ '../views/authorization/views/SetNewPassword')
          },
          {
            path: '/registration',
            name: 'Register',
            component: () => import(/* webpackChunkName: "Register" */ '../views/authorization/views/Register')
          },
          {
            path: '/registercompany',
            name: 'RegisterCompany',
            component: () => import(/* webpackChunkName: "Register" */ '../views/authorization/views/CompanyRegister')
          },
        ]
    }
]

export default backendmenu;