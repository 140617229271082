import Vue from 'vue'
import VueRouter from 'vue-router'
import backendmenu from './backend';
import authorization from './authorization';
import frontend from './frontend';

Vue.use(VueRouter)

const routes = [
  ...backendmenu,
  ...authorization,
  ...frontend
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
}
})

export default router
