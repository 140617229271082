import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  videos: [],
  categories: [],
  subcategories: [],
  lodingCrud: false,
  singleCategory: null,
  singleSubCategory: null,
  singleVideo: null,
});

const getters = {
  allVideo: (state) => state.videos,
  allSingleVideo: (state) => state.singleVideo,
};

const actions = {
  async addVideo({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.post(baseUrl + "video/create", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setVideo", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },

  async fetchVideo({ commit }, data) {
    try {
      let token = {};
      if (localStorage.getItem("token")) {
        token = {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        };
      }

      const response = await Axios.post(baseUrl + "video/list", data, token);
      commit("setVideo", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },

  async fetchSingleVideo({ commit }, id) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.get(`${baseUrl}video/single/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setSingleVideo", response.data);
    } catch (error) {
      console.error("Error fetching single video:", error);
    } finally {
      this.dispatch("loadingNotCrudFunc");
    }
  },

  async deleteVideoAction({ commit }, id) {
    if (!id) {
      console.error("No video ID provided for deletion");
      return false;
    }

    try {
      commit("loadingCrubSet", true);
      const response = await Axios.delete(`${baseUrl}video/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });

      if (response.data.success) {
        commit("removeVideo", id);
        return true;
      } else {
        throw new Error("Failed to delete video");
      }
    } catch (error) {
      console.error("Error deleting video:", error);
      return false;
    } finally {
      commit("loadingCrubSet", false);
    }
  },

  async updateVideo({ commit }, { id, data }) {
    try {
      commit("loadingCrubSet", true);
      const response = await Axios.put(`${baseUrl}video/${id}`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("updateVideoInState", response.data.results);
      return true;
    } catch (error) {
      console.error("Error updating video:", error);
      return false;
    } finally {
      commit("loadingCrubSet", false);
    }
  },

  async loadingCrubFunc({ commit }) {
    commit("loadingCrubSet", true);
  },

  async loadingNotCrudFunc({ commit }) {
    commit("loadingNotCrudSet", false);
  },
};

const mutations = {
  setVideo: (state, video) => (state.videos = video),
  setCategory: (state, categories) => (state.categories = categories),
  setSubcategory: (state, subcategories) =>
    (state.subcategories = subcategories),
  loadingCrubSet: (state, loading) => (state.loadingCrud = loading),
  loadingNotCrudSet: (state, loading) => (state.loadingCrud = loading),
  setSingleVideo: (state, video) => (state.singleVideo = video),
  removeVideo: (state, id) => {
    state.videos.results = state.videos.results.filter(
      (video) => video._id !== id
    );
  },
};

export default { state, getters, actions, mutations };
