import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  posts: [],
  post: [],
  loadingAd: false,
});

const getters = {
  allPosts: (state) => state.posts,
  allPost: (state) => state.post,
  allLoadinPost: (state) => state.loadingAd,
};

const actions = {
  async fetchPost({ commit }, data) {
    try {
      commit("setPosts", []);
      this.dispatch("loadingAdFunc");
      const response = await Axios.get(baseUrl + "post/list/" + data);
      commit("setPosts", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async fetchPostFront({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.get(baseUrl + "post/listfront/" + data);
      commit("setPosts", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },

  async fetchPostBySlug({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.get(baseUrl + `post/single/${data}`);
      commit("setPost", response.data);
    } catch (error) {
      
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },

  async addAd({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(baseUrl + "post/create", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setPost", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },

  async deleteBlogs({ commit }, data) {
    try {
      const response = await Axios.delete(baseUrl + `post/delete/${data}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setPosts", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },

  deleteAds: async ({ commit }, data) => {
    try {
      let response = await Axios.delete(baseUrl + "post/delete/" + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setPost", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async loadingAdFunc({ commit }) {
    commit("loadingAdSet", true);
  },
  async loadingNotAdFunc({ commit }) {
    commit("loadingNotAdSet", false);
  },
};

const mutations = {
  setPosts: (state, menu) => (state.posts = menu),
  setPost: (state, menu) => (state.post = menu),
  loadingAdSet: (state, loading) => (state.loadingAd = loading),
  loadingNotAdSet: (state, loading) => (state.loadingAd = loading),
};

export default { state, getters, actions, mutations };
