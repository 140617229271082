<template>
  <section>
    <footer>
      <v-container class="container-custom">
        <v-row>
          <v-col md="3">
            <img src="@/assets/footerlogo.png" alt="" class="logo" />
            <div class="aboutcontent" v-html="aboutcontent"></div>
          </v-col>
          <v-col md="2">
            <h3>Quick Links</h3>
            <ul class="navigation">
              <li><router-link to="/about">About</router-link></li>
              <li>
                <router-link to="/privacypolicy">Privacy Policy</router-link>
              </li>
              <li>
                <router-link to="/termsncondition"
                  >Terms & Conditions</router-link
                >
              </li>
              <li><router-link to="/contact">Contact Us</router-link></li>
            </ul>
          </v-col>
          <v-col md="2">
            <h3>For Employer</h3>
            <ul class="navigation">
              <li><router-link to="/login">Login</router-link></li>
              <li><router-link to="/companyregister">Register</router-link></li>
              <li><router-link to="/postajob">Post A Job</router-link></li>
            </ul>
          </v-col>
          <v-col md="2">
            <h3>For Job Seeker</h3>
            <ul class="navigation">
              <li><router-link to="/login">Login</router-link></li>
              <li><router-link to="/registration">Register</router-link></li>
              <li><router-link to="/search">Search A Job</router-link></li>
              <li><router-link to="/training">Trainings</router-link></li>
            </ul>
          </v-col>
          <v-col md="3">
            <h3>Contact Info</h3>
            <ul class="navigation">
              <li>Address : New Baneshwor Kathmandu, Nepal</li>
              <li style="display: flex; align-items: center">
                Training :
                <a href="tel:+977 9801855634" style="padding: 0 5px"
                  >+977 9801855634</a
                >
              </li>

              <li style="display: flex; align-items: center">
                Headhunting :
                <a href="tel:+977 9801855636" style="padding: 0 5px"
                  >+977 9801855636</a
                >
              </li>

              <li style="display: flex; align-items: center">
                Job Posting :
                <a href="tel:+977 9801855627" style="padding: 0 5px"
                  >+977 9801855627</a
                >
              </li>
              <li style="display: flex; align-items: center">
                Email :
                <a href="mailto:info@necojobs.com.np" style="padding: 0 5px"
                  >info@necojobs.com.np</a
                >
              </li>
              <li class="main-google">
                <!-- <a href="https://goo.gl/maps/cEaySxnmPrYuTRsy8" target="_blank"
                  >View Map <i class="fa fa-map-o"></i
                ></a> -->
                <iframe
                  class="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.8582391707473!2d85.3378785!3d27.690775999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1974c77c4745%3A0x503cc981d19634c9!2sNecoJobs%3A%20Leading%20Job%20Portal%20%26%20Professional%20Training%20in%20Nepal!5e0!3m2!1sen!2snp!4v1724143979686!5m2!1sen!2snp"
                  width="300"
                  height="160"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </footer>
    <div class="footer-bottom">
      <v-container class="container-custom">
        <v-row>
          <v-col md="4">
            <span class="text-left">
              {{ copywrite }}
            </span>
          </v-col>
          <v-col md="4">
            <ul class="sociallink">
              <li>
                <a
                  href="https://www.facebook.com/Necojobs"
                  target="_blank"
                  ><v-icon> mdi-facebook </v-icon></a
                >
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/neco-jobs-pvt-ltd/"
                  target="_blank"
                  ><v-icon> mdi-linkedin </v-icon></a
                >
              </li>
              <li>
                <a href="https://x.com/Necojobs" target="_blank"
                  ><v-icon> mdi-twitter </v-icon></a
                >
              </li>
              <li>
                <a href="https://www.instagram.com/necojobs/" target="_blank"
                  ><v-icon> mdi-instagram </v-icon></a
                >
              </li>
              <li>
                <a href="https://www.youtube.com/@neco2019/shorts" target="_blank"
                  ><v-icon> mdi-youtube </v-icon></a
                >
              </li>
            </ul>
          </v-col>
          <v-col md="4">
            <span class="text-right"
              >Developed By
              <a
                style="color: #fff; text-decoration: underline"
                href="https://upliftsolutions.info/"
              >
                Uplift Solutions</a
              ></span
            >
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    copywrite: `All rights reserved | 2024 Necojobs`,
    aboutcontent:
      "<p>Neco Jobs is a Growing platform for finding Online or Offline jobs in Nepal. Whether you're looking for IT or non-IT jobs, Neco Jobs helps you find a role you love, in a location you prefer, and get paid more. It connects you with your dream job with just a single click. Since its inception in 2075, Neco Jobs has rapidly grown into one of the best job portals in Kathmandu.</p>",
  }),
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.footer-bottom {
  background: $secondaryfooterback;
  .sociallink {
    text-align: center;
    a {
      padding: 0 5px;
      .v-icon {
        color: $white;
      }
    }
  }
  span {
    font-size: $midfont;
    display: block;
    letter-spacing: 1px;
    color: $white;
    margin-top: 3px;
  }
}
footer {
  background: $mainfooterback;
  padding: 30px 0;
  .logo {
    width: 100px;
    margin-bottom: 10px;
  }
  .aboutcontent {
    margin-bottom: 0;
    font-size: $normalfont;
    color: $white;
    text-align: justify;
  }
  h3 {
    margin-bottom: 10px;
    color: $white;
  }
  .navigation {
    display: flex;
    flex-flow: column;
    margin-left: 0;
    li {
      margin-left: 0;
      color: $white;
      font-size: $normalfont;
      a {
        text-decoration: none;
        display: block;
        padding: 3px 0;
        color: $white;
        font-size: $normalfont;
      }
    }
  }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .footer-bottom {
    background: #393939;
    span {
      text-align: center !important;
    }
  }
}
ul {
  list-style-type: none;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }
}
.google-map {
  margin-top: 8px;
}
@media screen and (max-width: 600px) {
  .google-map {
    width: 100%;
  }
  .main-google {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-left: 8px !important;
  }
}
</style>
