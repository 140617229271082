import Frontend from "../views/frontend/Frontend";
const backendmenu = [
  {
    path: "/",
    component: Frontend,
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/frontend/views/Home"),
        meta: {
          title: "Home | Best Jobs in Nepal",
          description: "Find the best jobs in Nepal.",
          keywords: "jobs in Nepal, best jobs, online jobs",
        },
      },
      {
        path: "/singlejob/:slug",
        name: "SingleJob",
        component: () => import("../views/frontend/views/SingleJob"),
      },
      {
        path: "/search/:slug",
        name: "SearchPage",
        component: () => import("../views/frontend/views/SearchPage"),
      },
      // searchjob
      {
        path: "/category/:category",
        name: "SearchPage",
        component: () => import("../views/frontend/views/SearchPage"),
      },
      {
        path: "/search",
        name: "SearchPage",
        component: () => import("../views/frontend/views/SearchPage"),
      },
      {
        path: "/addresume/:slugs",
        name: "ResumeUpdateFront",
        component: () => import("../views/frontend/views/ResumeAdd"),
      },
      {
        path: "/addresume/",
        name: "AddResumeFront",
        component: () => import("../views/frontend/views/ResumeAdd"),
      },
      {
        path: "/addcompany/",
        name: "AddCompanyFront",
        component: () => import("../views/frontend/views/CompanyAdd"),
      },
      {
        path: "/addjob/:company/:slug",
        name: "AddJob",
        component: () => import("../views/frontend/views/JobAdd"),
      },
      {
        path: "/addjob/:company",
        name: "AddJob",
        component: () => import("../views/frontend/views/JobAdd"),
      },
      {
        path: "/addcompany/:slug",
        name: "AddCompanyFrontUpdate",
        component: () => import("../views/frontend/views/CompanyAdd"),
      },
      {
        path: "/about",
        name: "AboutCompany",
        component: () => import("../views/frontend/views/About"),
      },
      {
        path: "/contact",
        name: "ContactPage",
        component: () => import("../views/frontend/views/Contact"),
      },
      {
        path: "/termsncondition",
        name: "Termsncondition",
        component: () => import("../views/frontend/views/Termsncondition"),
      },
      {
        path: "/privacypolicy",
        name: "PrivacyPolicy",
        component: () => import("../views/frontend/views/PrivacyPolicy"),
      },
      {
        path: "/whynecojobs",
        name: "Whynecojobs",
        component: () => import("../views/frontend/views/Whynecojobs"),
      },
      {
        path: "/whynecojobs",
        name: "Whynecojobs",
        component: () => import("../views/frontend/views/Whynecojobs"),
      },
      {
        path: "/courses",
        name: "Courses",
        component: () => import("../views/frontend/views/Courses"),
      },
      // {
      //   path: '/training',
      //   name: 'Trainings',
      //   component: () => import( '../views/frontend/views/Training')
      // },
      {
        path: "/training",
        name: "Trainings",
        component: () => import("../views/frontend/views/Training_new"),
      },
      {
        path: "/course/:slug",
        name: "TrainingSingle",
        component: () => import("../views/frontend/views/Courses"),
      },
      {
        path: "/blogs/:slug",
        name: "Blogs",
        component: () => import("../views/frontend/views/Blog"),
      },
      {
        path: "/singleBlog/:slug",
        name: "BlogSingle",
        component: () => import("../views/frontend/views/SingleBlog"),
      },
      {
        path: "/addcompany/:slug",
        name: "AddCompanyFrontUpdate",
        component: () => import("../views/frontend/views/CompanyAdd"),
      },
      {
        path: "/singlepage/:slug",
        name: "SinglePage",
        component: () => import("../views/frontend/views/SinglePage"),
      },
      {
        path: "/singeCompany/:slug",
        name: "SingleCompany",
        component: () => import("../views/frontend/views/SingleCompany"),
      },
      {
        path: "/postajob",
        name: "Postajob",
        component: () => import("../views/frontend/views/Postajob"),
      },
      {
        path: "/paymentchoose",
        name: "PaymentProcess",
        component: () => import("../views/frontend/views/NepalPaymentScreen"),
      },
      {
        path: "/paymentprocessime",
        name: "PaymentProcess",
        component: () => import("../views/frontend/views/ImePaymentScreen"),
      },
      {
        path: "/paymentprocess",
        name: "PaymentProcess",
        component: () => import("../views/frontend/views/NepalPaymentScreen"),
      },
      {
        path: "/videocourses",
        name: "VideoCourses",
        component: () => import("../views/frontend/views/VideoCourses"),
      },
      {
        path: "/searchvideo",
        name: "SearchVideo",
        component: () => import("../views/frontend/views/SearchVideo"),
      },
      {
        path: "/videopage/:id",
        name: "VideoPage",
        component: () => import("../views/frontend/views/VideoPage"),
      },
      {
        path: "/category",
        name: "Category",
        component: () => import("../views/frontend/views/Category"),
      },
      {
        path: "/allcourses",
        name: "AllCourses",
        component: () => import("../views/frontend/views/AllCourses"),
      }
    ],
  },
];

export default backendmenu;
