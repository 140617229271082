import Axios from "axios";
import { baseUrl } from "../../main";


const state = () => ({
  categories: [],
  subcategories: [],
  loadingCrud: false,
  singleCategory: null,
  singleSubCategory: null,
  videoCategories: [],
  videoSubCategories: [],
});

const getters = {
  allCategory: (state) => state.categories,
  allSubcategory: (state) => state.subcategories,
  allLoadingCurd: (state) => state.loadingCrud,
  allVideoCategory: (state) => state.videoCategories,
  allVideoSubCategory: (state) => state.videoSubCategories,
  allSingleSubCategory: (state) => state.singleSubCategory,
};

const actions = {
  async fetchCategory({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.post(baseUrl + "category/category", data);
      commit("setCategory", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async fetchCategoryVideo({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "category/category", data);
      commit("setCategoryVideo", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSubCategory({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.post(baseUrl + "category/subcategory", data);
      commit("setSubcategory", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async fetchVideoSubCategory({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "category/subcategory", data);
      commit("setVideoSubcategory", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchCategoryBySlug({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.get(baseUrl + `category/category/${data}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setCategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async fetchSubCategoryBySlug({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.get(
        baseUrl + `category/subcategory/${data}`
      );
      commit("setSubcategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async fetchSubCategoryById({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + `category/singleSubcategory/${data}`
      );
      commit("setSingleSubcategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async addCategory({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.post(
        baseUrl + "category/createcategory",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      // router.push("/dashboard/categories");
      commit("setSubcategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async addSubcategory({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.post(
        baseUrl + "category/createsubcategory",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      commit("setSubcategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async updateCategory({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.put(
        baseUrl + "category/category/update",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setCategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async updateSubcategory({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.put(
        baseUrl + "category/subcategory/update",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setSubcategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async deleteCategory({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.delete(baseUrl + `category/category/${data}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setCategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async deleteSubcategory({ commit }, data) {
    try {
      this.dispatch("loadingCrubFunc");
      const response = await Axios.delete(
        baseUrl + `category/subcategory/${data}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setSubcategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotCrudFunc");
  },
  async loadingCrubFunc({ commit }) {
    commit("loadingCrubSet", true);
  },
  async loadingNotCrudFunc({ commit }) {
    commit("loadingNotCrudSet", false);
  },
};

const mutations = {
  setCategory: (state, menu) => (state.categories = menu),
  setSubcategory: (state, menu) => (state.subcategories = menu),
  loadingCrubSet: (state, loading) => (state.loadingCrud = loading),
  loadingNotCrudSet: (state, loading) => (state.loadingCrud = loading),
  setCategoryVideo: (state, data) => (state.videoCategories = data),
  setVideoSubcategory: (state, data) => (state.videoSubCategories = data),
  setSingleSubcategory: (state, data) => (state.singleSubCategory = data),
};

export default { state, getters, actions, mutations };
