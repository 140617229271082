import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  basicInfo: [],
  jobPreference: [],
  education: [],
  training: [],
  workExperience: [],
  language: [],
  socialAccount: [],
  reference: [],
  otherInformation: [],
  resumes: [],
  loadingResume: false,
  resumeStatus: 0,
  uploadcv: [],
  uploadresume: [],
  singleResume: null,
  statusResume: false,
});

const getters = {
  allLoadingResume: (state) => state.loadingResume,
  allResume: (state) => state.resumes,
  allBasicInfo: (state) => state.basicInfo,
  alljobPreference: (state) => state.jobPreference,
  alleducation: (state) => state.education,
  alltraining: (state) => state.training,
  allWorkExperience: (state) => state.workExperience,
  allLanguage: (state) => state.language,
  allSocialAccount: (state) => state.socialAccount,
  allReference: (state) => state.reference,
  allotherInformation: (state) => state.otherInformation,
  allResumeStatus: (state) => state.resumeStatus,
  allUploadCv: (state) => state.uploadcv,
  allUploadedResume: (state) => state.uploadresume,
  allSingleResume: (state) => state.singleResume,
  allStatusResume: (state) => state.statusResume,
};

const actions = {
  async uploadedResume({ commit }, data) {
    this.dispatch("loadingResume");
    try {
      if (localStorage.getItem("token")) {
        const response = await Axios.post(baseUrl + "resume", data, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        });
        commit("setUploadedAll", response.data);
      }
    } catch (error) {
      console.log(error, "i am error");
    }
    this.dispatch("loadingNotResume");
  },
  async filterCV({ commit }, data) {
    this.dispatch("loadingResume");
    try {
      const response = await Axios.post(baseUrl + "resume/filter", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setUploadedAll", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },
  async uploadCV({ commit }, data) {
    this.dispatch("loadingResume");
    try {
      const response = await Axios.post(baseUrl + "fileupload/uploadcv", data);
      commit("setUploadedCV", response.data);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      commit("setUploadedCV", []);
    }, 5000);
    this.dispatch("loadingNotResume");
  },
  async finalsubmit({ commit }) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/singleResume",
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      if (this.state.user.logins.user.role != "user") {
        router.push("/dashboard/resumelist");
      } else {
        router.push("/");
      }

      commit("setResumeStatus", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async getResumeStatus({ commit }) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getResumeStatus",
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      commit("setResumeStatus", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async getBasicInfoAdmin({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getBasicInfo/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setBasicInfo", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getJobPreference({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getJobPreference/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setJobPreference", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getEducation({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getEducation/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setEducation", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getTraining({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getTraining/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setTraining", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getWorkExperience({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getWorkExperience/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setWorkExperience", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getLanguage({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getLanguage/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setLanguage", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSocialAccount({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getSocialAccount/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setSocialAccount", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getReference({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getReference/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setReference", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getOtherInformation({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "resumecomplete/getOtherInformation/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setOtherInformation", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getBasicInfoUser({ commit }) {
    try {
      const repsonse = await Axios.get(
        baseUrl + "resumecomplete/getBasicInfo",
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setBasicInfo", repsonse.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getAllResume({ commit }, data) {
    try {
      this.dispatch("loadingResume");
      const response = await Axios.post(
        baseUrl + "resumecomplete/resumeall",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setResumeAll", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },
  async createBasicInformation({ commit }, data) {
    try {
      this.dispatch("loadingResume");
      const searchdata = await Axios.post(
        baseUrl + "resumecomplete/createbasicinformation",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setBasicInfo", searchdata.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },
  async singelResume({ commit }, data) {
    try {
      commit("setResumeComplete", null);
      this.dispatch("loadingResume");
      const searchdata = await Axios.get(
        baseUrl + "resumecomplete/singleresume/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setResumeComplete", searchdata.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },

  async createJobPreference({ commit }, data) {
    try {
      this.dispatch("loadingResume");
      const searchdata = await Axios.post(
        baseUrl + "resumecomplete/createjobpreference",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setBasicInfo", searchdata.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },
  async createEducation({ commit }, data) {
    try {
      data.forEach(async (ele) => {
        this.dispatch("loadingResume");
        const searchdata = await Axios.post(
          baseUrl + "resumecomplete/createEducation",
          ele,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        commit("setEducation", searchdata.data);
      });
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },
  async createTraining({ commit }, data) {
    try {
      data.forEach(async (ele) => {
        this.dispatch("loadingResume");
        const searchdata = await Axios.post(
          baseUrl + "resumecomplete/createTraining",
          ele,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        commit("setTraining", searchdata.data);
      });
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResumeSet");
  },
  async createWorkExperience({ commit }, data) {
    try {
      data.forEach(async (ele) => {
        this.dispatch("loadingResume");
        const searchdata = await Axios.post(
          baseUrl + "resumecomplete/createWorkExperience",
          ele,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        commit("setWorkExperience", searchdata.data);
      });
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },
  async createLanguage({ commit }, data) {
    try {
      data.forEach(async (ele) => {
        this.dispatch("loadingResume");
        const searchdata = await Axios.post(
          baseUrl + "resumecomplete/createLanguage",
          ele,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        commit("setLanguage", searchdata.data);
      });
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },
  async createSocialAccount({ commit }, data) {
    try {
      data.forEach(async (ele) => {
        this.dispatch("loadingResume");
        const searchdata = await Axios.post(
          baseUrl + "resumecomplete/createSocialAccount",
          ele,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        commit("setSocialAccount", searchdata.data);
      });
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },

  async createReference({ commit }, data) {
    try {
      data.forEach(async (ele) => {
        this.dispatch("loadingResume");
        const searchdata = await Axios.post(
          baseUrl + "resumecomplete/createReferences",
          ele,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")).token,
            },
          }
        );
        commit("setReference", searchdata.data);
      });
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },
  async createOtherInformation({ commit }, data) {
    try {
      this.dispatch("loadingResume");
      const searchdata = await Axios.post(
        baseUrl + "resumecomplete/createOtherInformation",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setOtherInformation", searchdata.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResumeSet");
  },
  async filterResume({ commit }, data) {
    try {
      this.dispatch("loadingResume");
      const response = await Axios.post(
        baseUrl + "resumecomplete/filter",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setResumeAll", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotResume");
  },
  async updateStatus({ commit }, data) {
    try {
      const response = await Axios.put(baseUrl + "resume/status", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if (response.status == 200) {
        commit("setStatusResume", true);
      } else {
        commit("setStatusResume", false);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getResumeView({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + "job/singleResume/" + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setResumeComplete", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async loadingResume({ commit }) {
    commit("loadingResumeSet", true);
  },
  async loadingNotResume({ commit }) {
    commit("loadingNotResumeSet", false);
  },
};

const mutations = {
  setResumeAll: (state, resume) => (state.resumes = resume),
  setUploadedAll: (state, resume) => (state.uploadresume = resume),
  setBasicInfo: (state, resume) => (state.basicInfo = resume),
  setJobPreference: (state, resume) => (state.jobPreference = resume),
  setEducation: (state, resume) => (state.education = resume),
  setTraining: (state, resume) => (state.training = resume),
  setWorkExperience: (state, resume) => (state.workExperience = resume),
  setLanguage: (state, resume) => (state.language = resume),
  setSocialAccount: (state, resume) => (state.socialAccount = resume),
  setReference: (state, resume) => (state.reference = resume),
  setOtherInformation: (state, resume) => (state.otherInformation = resume),
  loadingResumeSet: (state, loading) => (state.loadingResume = loading),
  loadingNotResumeSet: (state, loading) => (state.loadingResume = loading),
  setResumeStatus: (state, loading) => (state.resumeStatus = loading),
  setUploadedCV: (state, loading) => (state.uploadcv = loading),
  setResumeComplete: (state, loading) => (state.singleResume = loading),
  setStatusResume: (state, loading) => (state.statusResume = loading),
};

export default { state, getters, actions, mutations };
